.form {
    background-color: #f3f3f1;
    padding: 10px 20px;
    margin-bottom: 35px;
    @media (min-width: 768px) {
        padding: 10px 25px;
    }
    @media (min-width: 992px) {
        padding: 25px 45px;
    }


    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    textarea,
    select.form-control {
        max-width: 500px;
        min-width: 390px;
        @media (max-width: 991px) {
            max-width: 100%;
            min-width: 250px;
            width: 100%;
        }
    }

    .error {
        color: red;
        font-size: 14px;
        margin-top: -22px;
    }
}

input[type=text],
input[type=number],
input[type=email],
input[type=password],
textarea,
select.form-control {
    box-shadow: none;
    border: 2px solid #dad9d6;
    color: #3e3e3e;
    margin-bottom: 25px;
    padding: 10px 15px;

    .ValidationFail > & {
        border-color: red;
    }
}

/* Disable clear cross button in IE text boxes */
input[type=text]::-ms-clear {
    display: none !important;
}

textarea {
    resize: vertical;
    min-width: 400px;
    min-height: 150px;
}

.form-group, .form {
    label, span.Form__Element__Caption {
        color: #231f20;
        display: block;
        font-weight: bold;
    }
}

.form-group, .form {
	label, legend.Form__Element__Caption {
		color: #005596;
		display: block;
		font-weight: bold;
		font-size: 16px;
	}
}

.radio-inline, .radio,
.checkbox-inline, .checkbox {
    margin-bottom: 25px;
    label {
        color: #2e2b27;
        font-weight: bold;
    }
}

label.checkbox-inline, .checkbox {
    color: #2e2b27;
    font-weight: bold;
}

.EPiServerForms.form {
	.FormChoice {
		.Form__Element__Caption {
			display: block;
			margin-bottom: 15px
		}

		label {
			color: black;
			position: relative;
			margin-bottom: 0;
			vertical-align: middle;
			cursor: pointer;
			font-weight: normal;
			margin-bottom: 20px;
			display: block;
			float: left;
			clear: both;
		}

		.FormChoice__Input, .FormChoice__Input--Radio {
			display: inline-block;
		}
	}

	.FormTextbox--Textarea > label {
		display: block;
	}
}

.EPiServerForms .Form__Element > label.Form__Element__Caption {
	display: block;
}
