.form {
  background-color: #f3f3f1;
  padding: 10px 20px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .form {
    padding: 10px 25px;
  }
}

@media (min-width: 992px) {
  .form {
    padding: 25px 45px;
  }
}

.form input[type=text],
.form input[type=number],
.form input[type=email],
.form input[type=password],
.form textarea,
.form select.form-control {
  max-width: 500px;
  min-width: 390px;
}

@media (max-width: 991px) {
  .form input[type=text],
  .form input[type=number],
  .form input[type=email],
  .form input[type=password],
  .form textarea,
  .form select.form-control {
    max-width: 100%;
    min-width: 250px;
    width: 100%;
  }
}

.form .error {
  color: red;
  font-size: 14px;
  margin-top: -22px;
}

input[type=text],
input[type=number],
input[type=email],
input[type=password],
textarea,
select.form-control {
  box-shadow: none;
  border: 2px solid #dad9d6;
  color: #3e3e3e;
  margin-bottom: 25px;
  padding: 10px 15px;
}

.ValidationFail > input[type=text], .ValidationFail >
input[type=number], .ValidationFail >
input[type=email], .ValidationFail >
input[type=password], .ValidationFail >
textarea, .ValidationFail >
select.form-control {
  border-color: red;
}

/* Disable clear cross button in IE text boxes */
input[type=text]::-ms-clear {
  display: none !important;
}

textarea {
  resize: vertical;
  min-width: 400px;
  min-height: 150px;
}

.form-group label, .form-group span.Form__Element__Caption, .form label, .form span.Form__Element__Caption {
  color: #231f20;
  display: block;
  font-weight: bold;
}

.form-group label, .form-group legend.Form__Element__Caption, .form label, .form legend.Form__Element__Caption {
  color: #005596;
  display: block;
  font-weight: bold;
  font-size: 16px;
}

.radio-inline, .radio,
.checkbox-inline, .checkbox {
  margin-bottom: 25px;
}

.radio-inline label, .radio label,
.checkbox-inline label, .checkbox label {
  color: #2e2b27;
  font-weight: bold;
}

label.checkbox-inline, .checkbox {
  color: #2e2b27;
  font-weight: bold;
}

.EPiServerForms.form .FormChoice .Form__Element__Caption {
  display: block;
  margin-bottom: 15px;
}

.EPiServerForms.form .FormChoice label {
  color: black;
  position: relative;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 20px;
  display: block;
  float: left;
  clear: both;
}

.EPiServerForms.form .FormChoice .FormChoice__Input, .EPiServerForms.form .FormChoice .FormChoice__Input--Radio {
  display: inline-block;
}

.EPiServerForms.form .FormTextbox--Textarea > label {
  display: block;
}

.EPiServerForms .Form__Element > label.Form__Element__Caption {
  display: block;
}
